import styled, {keyframes} from 'styled-components';

export const StyledForm = styled.form`
  &&&{
    .mktoButton {
      display: inline;
      padding: 12px 30px;
      border-radius: 100px;
      font-weight: bold;
      font-size: var(--fontSize-1);
      min-width: 175px;
      display: inline-block;
      text-align: center;
      background-color: var(--color-primary);
      color: white;
      font-family: var(--font-body);
      border: none;
      transition: all .2s;
      &:hover {
        color: white;
        text-decoration: none;
        background-color: var(--color-primary-dark);
      }
      &:active{
        background-color: var(--color-primary-darker);
      }
    }
    .mktoAsterix{
        display:none;
    }
    .mktoFormCol {
      ${(props) => props['data-formId'] === '1830' ? "" : "width: unset !important;"}
    }
    [name="Email"].customInvalid ~ .mktoError {
          display: block !important;
          opacity: 1 !important;
          max-width: 190px;
    } 
  }
`;

export const FormConfirm = styled.div`
    margin: 0 auto;
`;

export const LoaderHolder = styled.div`
  min-height: 150px;
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--color-primary);
  border-radius: 50%;
  animation: ${Spinner} 1.5s linear infinite;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
`;